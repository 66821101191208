import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "registrationNumberInput" ]

  async setVrakDate() {
    const registrationNumber = this.registrationNumberInputTarget.value
    if (registrationNumber.length === 7) {
      const date = await this.callRetriveVrakDate(registrationNumber)
      const vrak_date_input_field = document.getElementById("battery_vrak_date")
      if (date === null) { return }

      vrak_date_input_field.setAttribute('value', date)
      vrak_date_input_field.setAttribute('disabled', 'true')
    }
  }

  async callRetriveVrakDate(registrationNumber) {
    const response = await fetch(`/batteries/${registrationNumber}/retrieve_vrak_date`, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json"
      }
    });

    const data = await response.json();
    return data.date;
  }
}
